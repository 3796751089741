// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
    _18: ImageType,
    _19: ImageType,
    _20: ImageType,
    _21: ImageType,
    _22: ImageType,
    _23: ImageType,
    _24: ImageType,
    _25: ImageType,
    _26: ImageType,
    _27: ImageType,
    _28: ImageType,
    _29: ImageType,
    _30: ImageType,
    _31: ImageType,
    _32: ImageType,
    _33: ImageType,
    _34: ImageType,
    _35: ImageType,
  },
};

class Blog_2016_10_15_Aca_Leaderguide_Rendezvous_Egypt_1 extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3AII" title="Egypt 1" />
        <p>
          The objective for this canyon was to go down a ways and then ascend
          back up all the rappels. It made the canyon quite fun as we had to
          think through all our moves in reverse.
        </p>
        <p>Lots of pictures -</p>
        <Image image={data._1} />
        <Image image={data._2} />
        <Image image={data._3} />
        <p>
          Inspecting and rigging the first rappel. Since this was more about
          practicing skills rather than exploring, we rigged several different
          spots, each a different way.
        </p>
        <Image caption="Hiking down canyon" image={data._4} />
        <Image caption="Beautiful Tarantula" image={data._5} />
        <Image caption="Hiking" image={data._6} />
        <Image caption="Don't look or I'll mess up!" image={data._7} />
        <p>Rappel down one of the drops...</p>
        <Image image={data._9} />
        <Image image={data._10} />
        <Image image={data._11} />
        <Image image={data._12} />
        <Image image={data._13} />
        <Image image={data._14} />
        <Image image={data._15} />
        <p>
          ...and <GlossaryLink>ascending</GlossaryLink> back up.
        </p>
        <Image image={data._16} />
        <Image image={data._17} />
        <Image image={data._18} />
        <Image image={data._19} />
        <Image image={data._20} />
        <Image image={data._21} />
        <Image image={data._22} />
        <p>
          Cody pretended to be stuck and so those who were still at the top had
          to haul him up.
        </p>
        <Image caption="Some thinking" image={data._23} />
        <Image caption="Some acting" image={data._24} />
        <Image caption="And he's up!" image={data._25} />
        <Image caption="Group shot" image={data._26} />
        <Image caption="Don't forget me!" image={data._27} />
        <Image image={data._28} />
        <Image
          caption={<GlossaryLink>Moqui steps</GlossaryLink>}
          image={data._29}
        />
        <Image image={data._30} />
        <p>
          Our final obstacle on the way back up was to haul one of our group up
          over a free hang. With a good number of haulers and favorable
          geometry, it was an easy task.
        </p>
        <Image image={data._33} />
        <Image image={data._34} />
        <Image image={data._35} />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2016-10-15-aca-leaderguide-rendezvous-egypt-1')(
  Blog_2016_10_15_Aca_Leaderguide_Rendezvous_Egypt_1
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/9.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/10.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/11.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/12.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/13.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/14.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/15.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/16.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _17: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/17.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _18: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/18.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _19: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/19.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _20: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/20.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _21: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/21.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _22: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/22.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _23: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/23.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _24: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/24.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _25: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/25.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _26: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/26.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _27: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/27.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _28: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/28.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _29: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/29.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _30: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/30.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _33: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/33.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _34: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/34.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _35: file(
      relativePath: {
        eq: "images/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1/35.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
